$(document).ready(function () {

    /**
     * Global object
     *
     * @constructor
     */
    window.ThisWebsite = function () {
    };

    // When the user scrolls the page, execute myFunction
    // window.onscroll = function () {
    //     myFunction()
    // };



// // Get the navbar
//     var navbar = document.getElementById("navbar-main");
//
// // Get the offset position of the navbar
//     var sticky = navbar.offsetTop;
//
// // Add the sticky class to the navbar when you reach its scroll position. Remove "sticky" when you leave the scroll position
//     function myFunction() {
//         if (window.pageYOffset >= sticky) {
//             navbar.classList.add("navbar-main-sticky")
//         } else {
//             navbar.classList.remove("navbar-main-sticky");
//         }
//     }

    $('.bxslider').bxSlider({
        mode: 'fade',
        captions: true,
        //shrinkItems: true,
        slideMargin: 0,
        speed: 500,
        responsive: true,
        auto: true,

        touchEnabled: false,
        // oneToOneTouch: false,
        preventDefaultSwipeY: false
    });

    // var APL_YMAPS_PLACEMARK_DATA = [
    //     {
    //         'header': 'Прайд header',
    //         'body': 'Прайд body',
    //         'icon': '/aplab/filestorage/dce/3f9/110/dce3f911053e18da73c565371e36d776.png',
    //         'x': 56.838011,
    //         'y': 60.597465,
    //     }
    // ];

    ymaps.ready(function () {
        var myMap = new ymaps.Map('apl-ymap-container', {
            center: [56.838011, 60.597465],
            zoom: 11,
            controls: []
        }, {
            searchControlProvider: null,
            suppressMapOpenBlock: true
        });

        var data;
        try {
            data = APL_YMAPS_PLACEMARK_DATA;
        } catch (e) {
            data = [];
        }

        // Создаём макет содержимого.
        var MyIconContentLayout = ymaps.templateLayoutFactory.createClass(
            '<div class="apl-ymap-icon" style="background-image: url(\'$[properties.iconContent]\')"></div>'
        );

        for (var i = 0; i < data.length; i++) {
            var data_item = data[i];

            // console.log(data_item);

            var myPlacemarkWithContent = new ymaps.Placemark([data_item.y, data_item.x], {
                // hintContent: 'Собственный значок метки с контентом',
                // balloonContent: 'Текст метки',
                // Зададим содержимое заголовка балуна.
                balloonContentHeader: data_item.header,
                // Зададим содержимое основной части балуна.
                balloonContentBody: data_item.body,
                // Зададим содержимое нижней части балуна.
                //balloonContentFooter: 'Информация предоставлена:<br/>balloonContentFooter',
                // Зададим содержимое всплывающей подсказки.
                hintContent: data_item.header,


                // iconContent: '/aplab/filestorage/dce/3f9/110/dce3f911053e18da73c565371e36d776.png'
                // iconContent: '/aplab/filestorage/970/099/8ee/9700998ee350c31c8529cb15422fefab.png'
                // iconContent: '/aplab/filestorage/08f/808/730/08f808730a384f43d4b6e51c1153a389.png'
                iconContent: data_item.icon
            }, {
                // Опции.
                // Необходимо указать данный тип макета.
                iconLayout: 'default#imageWithContent',
                // Своё изображение иконки метки.
                iconImageHref: '/img/map/map_button.png',
                // Размеры метки.
                iconImageSize: [55, 73],
                // Смещение левого верхнего угла иконки относительно
                // её "ножки" (точки привязки).
                iconImageOffset: [-33, -73],
                // Смещение слоя с содержимым относительно слоя с картинкой.
                iconContentOffset: [6, -2],
                // Макет содержимого.
                iconContentLayout: MyIconContentLayout
            });

            myMap.geoObjects.add(myPlacemarkWithContent);
        }

        myMap.behaviors.disable('scrollZoom');
        //на мобильных устройствах... (проверяем по userAgent браузера)
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
            //... отключаем перетаскивание карты
            //myMap.behaviors.disable('drag');
        }

        // ymapsTouchScroll(myMap, {
        //     // preventScroll: false,
        //     // preventTouch: false,
        // });

        // myMap.controls.add('zoomControl', {right: 25, top:42});
        myMap.controls.add('zoomControl', {position: {left: '100px', top: '5px'}});
        myMap.controls.add('typeSelector', {position: {right: '100px', top: '5px'}});
    });

});
